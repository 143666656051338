<template>
	<b-container fluid style="padding-left:0; padding-right:0;">
		<latest-post-streamer />
		<b-container class="mt-3 mb-3">
			<b-row>
				<b-col>
					<h1>Hvad er SkvizBiz?</h1>
				</b-col>
			</b-row>
			<b-row>
				<b-col sm="12" md="6">
					<p>SkvizBiz er et program, som er specielt udviklet til vinduespudsere. Det hjælper dig med planlægning, fakturering, betalingspåmindelser, regnskab, budgetter, backup og <nuxt-link to="/hvorfor">mange andre ting</nuxt-link>.</p>
					<p>SkvizBiz er registreret hos erhvervsstyrelsen som godkendt standardbogføringsystem<sup><a href="#" @click="scroll_to_element_id('footnote1'); highlight_id('footnote1')">1</a></sup>, så du kan samle det hele et sted; og så er det endda online, så du både kan bruge det fra din computer, din tablet og din smartphone &mdash; og fra din nye computer, hvis den gamle skulle brænde sammen.</p>
					<p>Hvis du føler du mangler overblikket, eller hvis du bare gerne vil bruge mindre tid på kontoret, så prøv SkvizBiz.</p>
				</b-col>
				<b-col sm="12" md="6" class="d-none d-md-block">
					<p>Du kan prøve det gratis i en måned, helt uforpligtende. Vi hjælper dig godt i gang med en demokonto, og vores fri telefonsupport gælder også mens du bare prøver programmet.</p>
					<p>Hvis du ikke synes om programmet når du har prøvet det, så får du naturligvis alle dine data fra demokontoen med, så du kan bruge dem i et andet program. Men det tror vi nu ikke du vil have lyst til, når først du har prøvet SkvizBiz!</p>
				</b-col>
			</b-row>
		</b-container>
		<b-carousel id="main-carousel" controls indicators style="height: 480px; width: 100%;" background="#ababab" :interval="8000">
			<b-carousel-slide style="height:480px; background:none;">
				<lazy-image slot="img" class="img-fluid w-100" :src="require('~/assets/images/bigbox-savetime.jpg')" width="740" height="554" alt="Spar tid - masser af tid" />
				<h1 class="text-shadow">Spar tid - masser af tid</h1>
				<p class="text-shadow">Udskriv med få klik fakturaer eller indbetalingskort til en hel dag eller uge ad gangen, og afstem kundernes indbetalinger på få minutter. Spar de kedelige kontortimer væk.</p>
				<p><b-button size="lg" variant="primary" to="/hvorfor#flere-grunde/spar-tid" role="button">Læs mere</b-button></p>
			</b-carousel-slide>
			<b-carousel-slide style="height:480px; background:none;">
				<lazy-image slot="img" class="img-fluid w-100" :src="require('~/assets/images/calendar_screen.jpg')" width="1280" height="720" alt="Få bedre overblik" />
				<h1 class="text-shadow">Få bedre overblik</h1>
				<p class="text-shadow">SkvizBiz er det planlægningsværktøj, som du ikke har råd til at undvære, hvis du lever af at pudse vinduer eller gøre rent. Kalenderen giver dig overblik over hvad der skal laves hvornår, og hvor meget du tjener.</p>
				<p><b-button size="lg" variant="primary" to="/hvorfor#flere-grunde/bedre-overblik" role="button">Læs mere</b-button></p>
			</b-carousel-slide>
			<b-carousel-slide style="height:480px; background:none; font-size: .95em;">
				<lazy-image slot="img" class="img-fluid w-100" :src="require('~/assets/images/bigbox-money.jpg')" width="800" height="599" alt="Hvem har betalt og hvem skylder?" />
				<h1 style="font-size:2.2rem;" class="text-shadow">Hvem har betalt og hvem skylder?</h1>
				<p class="text-shadow">Det holder SkvizBiz da styr på for dig. Indlæs betalinger fra banken, og SkvizBiz afstemmer dem automatisk. <span class="">Du får nemt overblik over dine kunde, og kan rykke de dårlige betalere på få minutter.</span> Så nu er det slut med at bruge timer på at tjekke indbetalinger!</p>
				<p><b-button size="lg" variant="primary" to="/hvorfor#flere-grunde/rykkere" role="button">Læs mere</b-button></p>
			</b-carousel-slide>
			<b-carousel-slide style="height:480px; background:none;">
				<lazy-image slot="img" class="img-fluid w-100" :src="require('~/assets/images/bigbox-accounting.png')" width="738" height="469" alt="Gør regnskab til en leg" />
				<h1 class="text-shadow">Gør regnskab til en leg</h1>
				<p class="text-shadow">Okay, det lyder måske for godt til at være sandt. Men med finansmodulets automatiske funktioner går det i hvert fald så hurtigt, at du ikke når at blive træt af det.</p>
				<p><b-button size="lg" variant="primary" to="/hvorfor#flere-grunde/registrer-betalinger" role="button">Læs mere</b-button></p>
			</b-carousel-slide>
		</b-carousel>
		<b-container class="mt-3 mb-3 d-md-none">
			<b-row>
				<b-col>
					<p>Du kan prøve det gratis i en måned, helt uforpligtende. Vi hjælper dig godt i gang med en demokonto, og vores fri telefonsupport gælder også mens du bare prøver programmet.</p>
					<p>Hvis du ikke synes om programmet når du har prøvet det, så får du naturligvis alle dine data fra demokontoen med, så du kan bruge dem i et andet program. Men det tror vi nu ikke du vil have lyst til, når først du har prøvet SkvizBiz!</p>
				</b-col>
			</b-row>
		</b-container>
		<b-container style="margin-top: 2rem;">
			<b-card-group deck class="mb-3">
				<b-card class="my-justify" @click="$router.push({path: '/tilmelding'})" style="cursor: pointer;">
					<span class="fa-stack text-info" style="vertical-align:top; font-size:70px; align-self:center"><fa :icon="fasCircle" class="fa-stack-2x" /><fa :icon="fasThumbsUp" class="fa-stack-1x fa-inverse" /></span>
					<h2 class="w-100" style="text-align: center;">Tilmelding</h2>
					<p class="grow">Opret en gratis demo-konto som virker i 30 dage, eller bestil SkvizBiz allerede i dag.</p>
					<b-button block variant="primary" to="/tilmelding">Tilmeld</b-button>
				</b-card>
				<b-card class="my-justify" @click="$router.push({path: '/videoer'})" style="cursor: pointer;">
					<span class="fa-stack text-info" style="vertical-align:top; font-size:70px; align-self:center"><fa :icon="fasCircle" class="fa-stack-2x" /><fa :icon="fasVideo" class="fa-stack-1x fa-inverse" /></span>
					<!-- <b-img rounded="circle" center class="img-circle" src="data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Generic placeholder image" width="140" height="140" /> -->
					<h2 class="w-100" style="text-align: center;">Videoer</h2>
					<p class="grow">Se programmet i funktion og lær de mange smarte værktøjer godt at kende.</p>
					<b-button block variant="primary" to="/videoer">Se videoer</b-button>
				</b-card>
				<b-card class="my-justify" @click="$router.push({path: '/priser'})" style="cursor: pointer;">
					<span class="fa-stack text-info" style="vertical-align:top; font-size:70px; align-self:center"><fa :icon="fasCircle" class="fa-stack-2x" /><fa :icon="fasFileInvoiceDollar" class="fa-stack-1x fa-inverse" /></span>
					<h2 class="w-100" style="text-align: center;">Priser</h2>
					<p class="grow">Spar masser af tid på planlægning og administration uden at blive ruineret.</p>
					<b-button block variant="primary" to="/priser">Beregn din pris</b-button>
				</b-card>
			</b-card-group>
		</b-container>
		<b-container style="margin-top: 2rem;">
			<b-row>
				<b-col>
					<p><span id="footnote1"><sup>1</sup> SkvizBiz' finansmodul er registreret hos erhvervsstyrelsen som et godkendt standardbogføringssystem med registreringsnummer <code><a href="/registreringsbevis.pdf" target="_blank" style="font: inherit; background: inherit; color: inherit">fob668761</a></code>.</span></p>
				</b-col>
			</b-row>
		</b-container>
	</b-container>
</template>

<script>
import {
	fasCircle,
	fasThumbsUp,
	fasVideo,
	fasFileInvoiceDollar
} from "!!fa-icon-loader!~/assets/@fortawesome/fontawesome-pro/metadata/icons.json";
import {
	BContainer,
	BRow,
	BCol,
	BCarousel,
	BCarouselSlide,
	BButton,
	BCardGroup,
	BCard,
	BImg,
} from "bootstrap-vue";

// import prepareIcon from "~/assets/prepare-icon.js";
import LazyImage from "~/components/LazyImage";

export default {
	// head() {
	// 	return {
	// 		link: [
	// 			{
	// 				rel: 'preload',
	// 				href: require('~/assets/images/bigbox-savetime.jpg'),
	// 				as: 'image',
	// 			},
	// 			{
	// 				rel: 'preload',
	// 				href: require('~/assets/images/bigbox-savetime.jpg').replace(/\.jpg$/, ".webp"),
	// 				as: 'image',
	// 			},
	// 		],
	// 	}
	// },
	components: {
		BContainer,
		BRow,
		BCol,
		BCarousel,
		BCarouselSlide,
		BButton,
		BCardGroup,
		BCard,
		BImg,
		LazyImage,
		LatestPostStreamer: () => import("~/components/LatestPostStreamer.vue"),
	},
	computed: {
		fasCircle() {return fasCircle},
		fasThumbsUp() {return fasThumbsUp},
		fasVideo() {return fasVideo},
		fasFileInvoiceDollar() {return fasFileInvoiceDollar},
	},
	methods: {
		scroll_to_element_id(id) {
			let el = document.getElementById(id);
				console.log("scroll to " + id);
			if (el) {
				window.scrollTo({top: el.getBoundingClientRect().top + window.pageYOffset - 70, behavior: 'smooth'});
			}
		},
		highlight_id(id) {
			let el = document.getElementById(id);
			el.classList.add("highlighted");
			setTimeout(()=>{
				el.classList.add("animate-background");
				setTimeout(()=>{
					el.classList.remove("highlighted");

					setTimeout(()=>{
						el.classList.remove("animate-background");

					}, 2000)
				}, 1500)
			}, 100)
		}
	}
};
</script>

<style lang="scss">
.text-shadow {
	color: black;
	background: rgba(255, 255, 255, 0.8);
	// background-color: #f2fbff88;
	// background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23bbe9fd' fill-opacity='0.3' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
	border-radius: 4px;
	width: auto;
	display: inline-block;
	padding: 0px 10px 2px;
}

#main-carousel .carousel-item {
	background-color: #959b9e;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23aab1b3' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
	overflow: hidden !important;

	> img {
		opacity: .4;
		width: auto !important;
		height: auto;
		min-width: 100%;
		min-height: 100%;
		max-width: initial;
		max-height: initial;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
}

.my-justify > .card-body {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.grow {
		flex: 1 1 auto;
	}
}

.animate-background {
	transition: background 1s ease-in;
}
.highlighted {
	background: yellow;
}
</style>
